<template>
	<div class="content">
		<common-header bg_size="100% calc(env(safe-area-inset-top) + 3.16rem)" :is-return="false" style="padding-top: env(safe-area-inset-top);"></common-header>
		<div class="head">
			<div class="head_left">
				<div class="img">
					<img :src="avatar_img" v-if="avatar_img != ''"/>
				</div>
				<div class="head_center">
					<div class="phone">{{userInfo.phone}}</div>
					<div class="vip" v-if="vipInfo.active"><img src="../../assets/imgs/new_color/my_vip.png" >{{vipInfo.level | vip_level}}</div>
				</div>
			</div>
		</div>
		<div class="K_VIP">
			<div class="main7">
			  <div class="section5">
				  <span class="word7">{{$t('tba.kaitongvipxiangfuli')}}</span>
			  </div>
			  <div class="section6" @click="routreTo('/memberClub')">
				<span class="word8">{{$t('tba.lijikaitong')}}</span>
			  </div>
			  <!-- <div class="section6" @click="routreTo('/community2')">
					<span class="word8">{{$t('tba.lijikaitong')}}</span>
			  </div> -->
			</div>
		</div>
		<div class="seation">
			<div class="qb" @click="routreTo('/assets')">
			  <div class="qb_left">
				  <img src="../../assets/imgs/new_color/qianbao@2x.png" >
				  <span class="word7">{{$t('xinzeng.qianbao')}}</span>
			  </div>
			  <div class="qb_right">
				  <span class="word7">{{assetsDitale}} USDT</span>
			      <img src="../../assets/imgs/new_color/tiaozhuan.png" >
			  </div>
			</div>
		</div>
		<div class="seation1">
			<div class="qb" @click="routreTo('/sharePage')" v-if="vipInfo.active">
			  <div class="qb_left">
				  <img src="../../assets/imgs/new_color/yaoqing@2x.png" >
				  <span class="word7">{{$t('tba.invitefriends')}}</span>
			  </div>
			  <div class="qb_right">
			      <img src="../../assets/imgs/new_color/tiaozhuan.png" >
			  </div>
			</div>
			<div class="qb" @click="routreTo('/tobeVip')" v-else>
			  <div class="qb_left">
				  <img src="../../assets/imgs/new_color/yaoqing@2x.png" >
				  <span class="word7">{{$t('tba.invitefriends')}}</span>
			  </div>
			  <div class="qb_right">
			      <img src="../../assets/imgs/new_color/tiaozhuan.png" >
			  </div>
			</div>
		</div>
		<div class="seation2">
			<div class="qb" @click="routreTo('/community2')">
			  <div class="qb_left">
				  <img src="../../assets/imgs/new_color/team@2x.png" >
				  <span class="word7">{{$t('tba.myteam')}}</span>
			  </div>
			  <div class="qb_right">
			      <img src="../../assets/imgs/new_color/tiaozhuan.png" >
			  </div>
			</div>
		</div>
		<div class="seation3">
			<div class="qb" @click="routreTo('/transactionRecords')">
			  <div class="qb_left">
				  <img src="../../assets/imgs/new_color/jilu@2x.png" >
				  <span class="word7">{{$t('tba.TransactionRecord')}}</span>
			  </div>
			  <div class="qb_right">
			      <img src="../../assets/imgs/new_color/tiaozhuan.png" >
			  </div>
			</div>
		</div>
		<!-- <div class="seation4">
			<div class="qb">
			  <div class="qb_left">
				  <img src="../../assets/imgs/new_color/gongju@2x.png" >
				  <span class="word7">{{$t('tba.tool')}}</span>
			  </div>
			  <div class="qb_right">
			      <img src="../../assets/imgs/new_color/tiaozhuan.png" >
			  </div>
			</div>
		</div> -->
		<div class="seation4">
			<div class="qb" @click="routreTo('/securityCenter')">
			  <div class="qb_left">
				  <img src="../../assets/imgs/new_color/anquanzhongxin@2x.png" >
				  <span class="word7">{{$t('tba.Securitycenter')}}</span>
			  </div>
			  <div class="qb_right">
			      <img src="../../assets/imgs/new_color/tiaozhuan.png" >
			  </div>
			</div>
		</div>
		<div class="seation6">
			<div class="qb" @click="routreTo('/consultingService')">
			  <div class="qb_left">
				  <img src="../../assets/imgs/new_color/zixun@2x.png" >
				  <span class="word7">{{$t('tba.article')}}</span>
			  </div>
			  <div class="qb_right">
			      <img src="../../assets/imgs/new_color/tiaozhuan.png" >
			  </div>
			</div>
		</div>
		<div class="seation7">
			<div class="qb" @click="routreTo('/aboutOurs')">
			  <div class="qb_left">
				  <img src="../../assets/imgs/new_color/aboutour@2x.png" >
				  <span class="word7">{{$t('tba.aboutus')}}</span>
			  </div>
			  <div class="qb_right">
			      <img src="../../assets/imgs/new_color/tiaozhuan.png" >
			  </div>
			</div>
		</div>
		<div class="login_out" @click="logOut">{{$t('tba.tuichudenglu')}}</div>
		<div class="kong"></div>
		<van-tabbar v-model="active" active-color="#5569FC" inactive-color="#ACB5BC" route safe-area-inset-bottom>
		  <van-tabbar-item replace to="/">
		    <span>{{ $t('common.home') }}</span>
		    <template #icon="props">
		      <img v-if="props.active" src="../../assets/imgs/new_color/home_a.png" />
			  <img v-else src="../../assets/imgs/new_color/home.png" />
		    </template>
		  </van-tabbar-item>
		 <van-tabbar-item replace to="/quantification">
		    <span>{{ $t('tba.quant') }}</span>
		    <template #icon="props">
		      <img v-if="props.active" src="../../assets/imgs/new_color/lianghua_a.png" />
		      <img v-else src="../../assets/imgs/new_color/lianghua.png" />
		    </template>
		  </van-tabbar-item>
		  <van-tabbar-item replace to="/my">
		    <span>{{ $t('xinzeng.wode') }}</span>
		    <template #icon="props">
		      <img v-if="props.active" src="../../assets/imgs/new_color/my_a.png" />
		      <img v-else src="../../assets/imgs/new_color/my.png" />
		    </template>
		  </van-tabbar-item>
		</van-tabbar>
		
	</div>
</template>

<script>
	import listIconArr from '@/components/list/listIconArr.vue'
	import { logout } from "@/router/index.js"
	import commonHeader from '@/components/commonHeader';
	export default {
		name: "my",
		components: { commonHeader },
		data() {
			return {
				active: 2,
				userInfo: {},
				expire_day: '',
				activeIfVar: false,
				helpId: '',
				vipInfo: {},
				avatar_img: '',
				tab_index: 2,
				exchangeMap: {
					huobi: 1,
					bian: 2,
					okex: 3,
					uniswap: 4
				},
				exchange: '',
				jiaoyiLX : 'spot', 
				assetsDitale: 0, //可用货币余额
				StatusbarHeight: ''
			}
		},
		filters: {
			vip_level(v) {
				if(v == -1) {
					return 0
				}else {
					return v;
				}
			}
		},
		mounted() {
			// 获取用户信息
			let userinfo = JSON.parse(localStorage.getItem('userInfo'))
			this.userInfo = userinfo
			this.exchange = userinfo.default_exchange;
			this.getAssets();
			// console.log(userinfo)
		},
		created() {
			this.getClubMemberInfo();
			this.GetSystemImgList();
		},
		methods: {
			routreTo(e) {
				this.$router.push(e)
			},
			tab_qie(e) {
				this.tab_index = e;
				if (e == 0) {
					this.$router.push('/newHome');
				} else if (e == 1) {
					this.$router.push('/quantification');
				} else if (e == 2) {
					this.$router.push('/my');
				}
			},
			// 验证是否为会员用户，获取用户会员等级信息
			getClubMemberInfo() {
				this.$post2('User/Api/Index/getClubInfo', {}).then(res => {
					this.vipInfo = res;
					// console.log('vipInfo', this.vipInfo)
				});
			},
			// 获取资产信息
			getAssets() {
			  this.$post2("User/Api/Index/userAsset").then((res) => {
			    console.log(res);
			    // 总资产是 amout + frozen
			
			    let t = res.USDT.amount + res.USDT.frozen;
			    this.assetsDitale = t.toFixed(2);
			    // let t2 = res.REWARD.amount + res.REWARD.frozen;
			    // this.rewardData = t2.toFixed(2);
			    // console.log(typeof res.USDT.amount)
			    // this.amount =  res.amount
			    // + res.frozen
			  });
			},
			logOut() {
				console.log('logOut')
				this.$post2("user/api/passport/logout", {})
				    .then(res => {
				        logout()
				    })
			},
			//获取logo图
			GetSystemImgList() {
				this.$post2('User/Api/Passport/GetSystemImgList  ', {})
					.then(res => {
						this.avatar_img = res.list[0].url;
					})
					.catch(e => {
						// this.$toast.fail(e);
					});
			}
		},
	}
</script>

<style scoped lang="less">
	.content {
		font-family: PingFangSC-Regular;
		// padding-bottom: 1.6rem;
		// font-family: PingFangSC-Medium;
	}
	.head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		// top: 0.88rem;
		padding-top: calc(env(safe-area-inset-top) + 0.45rem);
		left: 0;
		width: 100%;
		&::before{
			content: "";
			height: calc(env(safe-area-inset-top) + 3.16rem);
			width: 100%;
			background: url(../../assets/imgs/new_color/my_head_bg.png) no-repeat center top;
			background-size: 100% 100%;
			display: inline-block;
			position: absolute;
			top: 0;
		}
		.head_left {
			z-index: 100;
			margin-left: 0.6rem;
			display: flex;
			align-items: center;
			.head_center {
				margin-left: 0.36rem;
				.phone {
					color: #FFFFFF;
					font-size: 0.28rem;
					font-weight: 500;
					text-align: left;
				}
				.vip {
					height: 0.37rem;
					font-size: 0.32rem;
					line-height: 0.37rem;
					color: #EFBA0D;
					font-family: 'haose';
					font-weight: 550;
					img {
						margin-right: 0.05rem;
						width: 0.44rem;
						height: 0.2rem;
					}
				}
			}
			.img {
				width: 1rem;
				height: 1rem;
				img {
					background-color: #FFFFFF;
					width: 100%;
					height: 100%;
					border-radius: 50%;
				}
			}
		}
		.head_right {
			margin-right: 0.6rem;
			.icon {
				img {
					width: 0.19rem;
					height: 0.32rem;
				}
			}
		}
	}
	.K_VIP {
		position: relative;
		z-index: 0.2rem;
		margin: 0 0.2rem;
		margin-top: 0.85rem;
		padding: 0.05rem 0;
		background-image: url(../../assets/imgs/new_color/Open_bg.png);
		background-size: 100% 100%;
		height: 0.86rem;
		.main7 {
			display: flex;
			justify-content: space-between;
			.section5 {
				margin-top: 0.14rem;
				margin-left: 1.2rem;
				.word7 {
					color: #2F4B9D;
					font-size: 0.24rem;
					font-weight: 550;
				}
			}
			.section6 {
				margin-top: 0.14rem;
				margin-right: 0.9rem;
				.word8 {
					font-size: 0.24rem;
					color: #FFFFFF;
					font-weight: 550;
				}
			}
		}
	}
	.seation {
		margin: 0 0.2rem;
		margin-top: 0.2rem;
		border-radius: 0.16rem;
		height: 1.11rem;
		line-height: 1.11rem;
		background-color: #FFFFFF;
		.qb {
			margin: 0 0.2rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.qb_left {
				display: flex;
				align-items: center;
				img {
					width: 0.6rem;
					height: 0.6rem;
				}
				.word7 {
					font-family: PingFangSC-Regular;
					margin-left: 0.12rem;
					color: #6F7989;
					font-size: 0.28rem;
				}
			}
			.qb_right {
				display: flex;
				align-items: center;
				img {
					margin-left: 0.4rem;
					width: 0.14rem;
					height: 0.24rem;
				}
				.word7 {
					font-family: PingFangSC-Medium;
					color: #333333;
					font-weight: bold;
					font-size: 0.32rem;
				}
			}
		}
	}
	
	.seation1 {
		margin: 0 0.2rem;
		margin-top: 0.2rem;
		border-top-left-radius: 0.16rem;
		border-top-right-radius: 0.16rem;
		height: 1rem;
		line-height: 1rem;
		background-color: #FFFFFF;
		.qb {
			margin: 0 0.2rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.qb_left {
				display: flex;
				align-items: center;
				img {
					width: 0.6rem;
					height: 0.6rem;
				}
				.word7 {
					font-family: PingFangSC-Regular;
					margin-left: 0.12rem;
					color: #6F7989;
					font-size: 0.28rem;
				}
			}
			.qb_right {
				display: flex;
				align-items: center;
				img {
					margin-left: 0.4rem;
					width: 0.14rem;
					height: 0.24rem;
				}
				.word7 {
					color: #333333;
					font-weight: 500;
					font-size: 0.32rem;
				}
			}
		}
	}
	.seation2 {
		margin: 0 0.2rem;
		height: 1rem;
		line-height: 1rem;
		background-color: #FFFFFF;
		.qb {
			margin: 0 0.2rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.qb_left {
				display: flex;
				align-items: center;
				img {
					width: 0.6rem;
					height: 0.6rem;
				}
				.word7 {
					font-family: PingFangSC-Regular;
					margin-left: 0.12rem;
					color: #6F7989;
					font-size: 0.28rem;
				}
			}
			.qb_right {
				display: flex;
				align-items: center;
				img {
					margin-left: 0.4rem;
					width: 0.14rem;
					height: 0.24rem;
				}
				.word7 {
					color: #333333;
					font-weight: 500;
					font-size: 0.32rem;
				}
			}
		}
	}
	.seation3 {
		margin: 0 0.2rem;
		border-bottom-left-radius: 0.16rem;
		border-bottom-right-radius: 0.16rem;
		height: 1rem;
		line-height: 1rem;
		background-color: #FFFFFF;
		.qb {
			margin: 0 0.2rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.qb_left {
				display: flex;
				align-items: center;
				img {
					width: 0.6rem;
					height: 0.6rem;
				}
				.word7 {
					margin-left: 0.12rem;
					color: #6F7989;
					font-size: 0.28rem;
				}
			}
			.qb_right {
				display: flex;
				align-items: center;
				img {
					margin-left: 0.4rem;
					width: 0.14rem;
					height: 0.24rem;
				}
				.word7 {
					color: #333333;
					font-weight: 500;
					font-size: 0.32rem;
				}
			}
		}
	}
	.seation4 {
		margin: 0 0.2rem;
		margin-top: 0.2rem;
		border-top-left-radius: 0.16rem;
		border-top-right-radius: 0.16rem;
		height: 1rem;
		line-height: 1rem;
		background-color: #FFFFFF;
		.qb {
			margin: 0 0.2rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.qb_left {
				display: flex;
				align-items: center;
				img {
					width: 0.6rem;
					height: 0.6rem;
				}
				.word7 {
					font-family: PingFangSC-Regular;
					margin-left: 0.12rem;
					color: #6F7989;
					font-size: 0.28rem;
				}
			}
			.qb_right {
				display: flex;
				align-items: center;
				img {
					margin-left: 0.4rem;
					width: 0.14rem;
					height: 0.24rem;
				}
				.word7 {
					color: #333333;
					font-weight: 500;
					font-size: 0.32rem;
				}
			}
		}
	}
	.seation5 {
		margin: 0 0.2rem;
		height: 1rem;
		line-height: 1rem;
		background-color: #FFFFFF;
		.qb {
			margin: 0 0.2rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.qb_left {
				display: flex;
				align-items: center;
				img {
					width: 0.6rem;
					height: 0.6rem;
				}
				.word7 {
					font-family: PingFangSC-Regular;
					margin-left: 0.12rem;
					color: #6F7989;
					font-size: 0.28rem;
				}
			}
			.qb_right {
				display: flex;
				align-items: center;
				img {
					margin-left: 0.4rem;
					width: 0.14rem;
					height: 0.24rem;
				}
				.word7 {
					color: #333333;
					font-weight: 500;
					font-size: 0.32rem;
				}
			}
		}
	}
	
	.seation6 {
		margin: 0 0.2rem;
		height: 1rem;
		line-height: 1rem;
		background-color: #FFFFFF;
		.qb {
			margin: 0 0.2rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.qb_left {
				display: flex;
				align-items: center;
				img {
					width: 0.6rem;
					height: 0.6rem;
				}
				.word7 {
					font-family: PingFangSC-Regular;
					margin-left: 0.12rem;
					color: #6F7989;
					font-size: 0.28rem;
				}
			}
			.qb_right {
				display: flex;
				align-items: center;
				img {
					margin-left: 0.4rem;
					width: 0.14rem;
					height: 0.24rem;
				}
				.word7 {
					color: #333333;
					font-weight: 500;
					font-size: 0.32rem;
				}
			}
		}
	}
	.seation7 {
		margin: 0 0.2rem;
		height: 1rem;
		line-height: 1rem;
		background-color: #FFFFFF;
		border-bottom-left-radius: 0.16rem;
		border-bottom-right-radius: 0.16rem;
		.qb {
			margin: 0 0.2rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.qb_left {
				display: flex;
				align-items: center;
				img {
					width: 0.6rem;
					height: 0.6rem;
				}
				.word7 {
					font-family: PingFangSC-Regular;
					margin-left: 0.12rem;
					color: #6F7989;
					font-size: 0.28rem;
				}
			}
			.qb_right {
				display: flex;
				align-items: center;
				img {
					margin-left: 0.4rem;
					width: 0.14rem;
					height: 0.24rem;
				}
				.word7 {
					color: #333333;
					font-weight: 500;
					font-size: 0.32rem;
				}
			}
		}
	}
	.login_out {
		margin: 0 0.2rem;
		margin-top: 0.2rem;
		border-radius: 0.16rem;
		height: 1rem;
		line-height: 1rem;
		text-align: center;
		color: #2E62FF;
		background-color: #FFFFFF;
		font-weight: bold;
		font-size: 0.32rem;
	}
	.kong {
		width: 100%;
		height: 2.6rem;
	}
	
	.tab_bottom {
		display: flex;
		justify-content: space-between;
		position: fixed;
		left: 0;
		bottom: 0;
		height: 1.14rem;
		width: 100%;
		background-color: #ffffff;
		border-top: 0.005rem solid #dedede;
		.tab_item {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0.14rem 0.8rem;
			.logo {
				width: 0.4rem;
				height: 0.4rem;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.logo1 {
				width: 0.32rem;
				height: 0.4rem;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.txt {
				color: #acb5bc;
				margin-top: 0.04rem;
				font-size: 0.24rem;
				transform: scale(0.916);
			}
			.active {
				color: #efba0d;
				font-family: PingFangSC-Medium;
			}
		}
	}
	.van-nav-bar__content {
		height: 1.6rem !important;
	}
</style>
