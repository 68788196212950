<template>
	<div class="list-icon-arrR" @click="routerTo">
		<div class="label">
			<div class="icon"><slot name="icon"></slot></div>
			{{ label }}
		</div>
		<div class="info">
			<i class="icon_arrR"></i>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'listIconArr',
		props: {
			label: {
				style: String,
				default: ''
			},
			linkUrl: {
				style: String,
				default: '/'
			},
			queryParams: {
				style: Object,
				default: () => {}
			},
		},
		data() {
			return {
				isset_tradepwd: null,
				route_path: ''
			}
		},
		created() {
			this.route_path = this.$route.path;
		},
		methods: {
			routerTo() {
				if (this.linkUrl && this.linkUrl !== '/') {
					// this.$router.push(this.linkUrl)
					if(this.linkUrl == '/chargeMoney'){
						this.issetTradepwd();
					}else {
						this.$router.push({
							path: this.linkUrl,
							query: this.queryParams,
						})
					}
				}
			},
			issetTradepwd() {
			  this.$post2("user/api/index/issetTradepwd",{
				  noToast: true
			  })
			  .then((res) => {
				  this.isset_tradepwd = res.isset_tradepwd;
				  if(this.isset_tradepwd == 0) {
				  	this.$router.push({
						path: '/setThePassword',
						query: {
							homepath: this.route_path
						},
					});
				  }else {
				  	this.$router.push({
				  		path: this.linkUrl,
				  		query: this.queryParams,
				  	})
				  }
			  })
			  .catch(e => {
				  // console.log(e);
			  });
			},
		}
	}
</script>

<style scoped lang="less">
	.list-icon-arrR {
		padding: .2rem 0;
		height: .88rem;
		border-bottom: 1px solid #EDEDED;
		display: flex;
		justify-content: space-between;
		.icon {
			width: .48rem;
			height: .48rem;
			margin-right: .12rem;
			img {
				width: 100%;
				display: block;
			}
		}
		.label {
			color: #666666;
			font-size: .28rem;
			display: flex;
			align-items: center;
		}
	}
</style>
